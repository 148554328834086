
import { defineComponent } from 'vue';

import QuestionsResolver from '@/components/questions/QuestionsResolver.vue';
import { useGetImage } from '@/composables';

export default defineComponent({
	components: {
		QuestionsResolver
	},

	setup() {
		const { getHomeImage } = useGetImage();

		return {
			catImage: getHomeImage('cat-tablet')
		};
	}
});
