<template>
	<section class="view-cat-questions">
		<questions-resolver type="cat" />
	</section>

	<picture>
		<source media="(min-width: 1024px)" srcset="@/assets/images/view-home/cat-hd.png" />
		<img :src="catImage" alt="" class="view-cat-questions__cat-img" />
	</picture>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import QuestionsResolver from '@/components/questions/QuestionsResolver.vue';
import { useGetImage } from '@/composables';

export default defineComponent({
	components: {
		QuestionsResolver
	},

	setup() {
		const { getHomeImage } = useGetImage();

		return {
			catImage: getHomeImage('cat-tablet')
		};
	}
});
</script>

<style lang="scss">
.view-cat-questions {
	@include question-wrapper;

	&__cat-img {
		min-width: 48.5rem;
		margin-bottom: -1rem;
		position: fixed;
		bottom: 13rem;
		left: calc(50% - 69rem);
		transform: translateX(-50%);
		pointer-events: none;

		@include under-hd {
			bottom: 10rem;
		}

		@include max-desktop {
			min-width: 48.5rem * 0.85;
			max-width: 48.5rem * 0.85;
		}

		@include bp(1650) {
			left: calc(50% - 59rem);
		}

		@include max-laptop {
			min-width: 48.5rem * 0.8;
			max-width: 48.5rem * 0.8;
			left: calc(50% - 55rem);
		}

		@include bp(1200) {
			left: calc(50% - 36vw);
		}

		@include bp(700, $height: true) {
			min-width: 48.5rem * 0.6;
			max-width: 48.5rem * 0.6;
		}

		@include max-tablet {
			min-width: 283px;
			max-width: 283px;
			left: calc(50% - 35vw);
		}

		@include tablet-portrait {
			min-width: 372px;
			max-width: 372px;
			bottom: 125px;
			left: calc(50% - 4vw);
		}

		@include max-phablet {
			min-width: 200px;
			max-width: 200px;
			bottom: 80px;
			left: calc(50% - 1.7vw);
		}
	}
}
</style>
